import { Constants } from "../../constants/Constants";
import { axiosInstance } from "../apiHeader.service"

const commonApi={
    fetchUser: function () {
        return axiosInstance.get<IAuthUser>(`${Constants.USER_ME_URI}`)
        .then((res)=>{
            return res.data;
        })
        .catch((error)=>{
            throw error;
        })
    },
    fetchCountry:async function(){
        const response=await axiosInstance.get<ICountry[]>(`${Constants.COUNTRY_GET_URI}`)
        return response.data
    },
    fetchTimezone:async function(){
        const response=await axiosInstance.get<ITimeZone[]>(`${Constants.TIMEZONE_GET_URI}`)
        return response.data
    },
    fetchStateByCountryid:async function(id:string|undefined){
        const response=await axiosInstance.get<IState[]>(`${Constants.STATEBYCOUNTRYID_GET_URI}/${id}`)
        return response.data
    },
    fetchSubscriptionExpired:async function(){
        const response=await axiosInstance.get(`${Constants.GET_SUBSCRIPTION_EXPIRED}`)
        return response
    }
}

export default commonApi;